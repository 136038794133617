<template>
  <v-row v-if="!fetchingYear" justify="center" class="ma-0 pa-0">
    <v-col cols="12" class="my-4">
      <h1 class="text-h4 primary--text text-center">Affectation d'ouvrages</h1>
    </v-col>

    <v-col cols="12" sm="8" md="8">
      <v-card>
        <v-stepper alt-labels v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step step="1">Recherche</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2">Sélection des utilisateurs</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">Sélection des ouvrages</v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step step="4">Résumé</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form lazy-validation ref="recherche-utilisateur">
                <v-select v-model="anneeScolaire" :items="anneesScolaires" label="Année scolaire" class="pa-1" clearable
                  :rules="[() => !!anneeScolaire || 'Ce champ est requis']" required></v-select>

                <v-autocomplete v-model="etablissement" :items="etablissements" :loading="loading" persistent-hint
                  label="Etablissement *" class="pa-1" :rules="[() => !!etablissement || 'Ce champ est requis']"
                  required></v-autocomplete>

                <v-select v-model="classe" :items="classes" label="Classe *" class="pa-1"
                  :rules="[() => !!classe || 'Ce champ est requis']" required></v-select>

                <v-select v-model="status" :items="statuts" label="Statut des utilisateurs" class="pa-1"
                  clearable></v-select>

                <v-btn name="Rechercher" height="44px" color="primary" dark class="AccessButton pl-3 pr-3" tile
                  :loading="loading" elevation="0" fab @click="validate" width="180">
                  Rechercher
                  <v-spacer />
                  <v-icon class="ml-5"> mdi-magnify</v-icon>
                </v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-data-table v-model="selectedUsers" :options.sync="pageInfo" :headers="headers" :items="utilisateurs"
                :loading="loading" :server-items-length="pageInfo.totalItems" item-key="identifiant" show-select
                @toggle-select-all="selectAll" @update:sort-by="changeSort" @update:sort-desc="changeSortDesc"
                @update:page="setPage" @update:items-per-page="setPageSize" class="elevation-1">
                <template v-slot:item.data-table-select="{ expand, index, item }">
                  <v-simple-checkbox @click="selectUser(item)" :disabled="loading"
                    :value="selectedUsers.map(u => u.identifiant).indexOf(item.identifiant) >= 0"
                    :ripple="false"></v-simple-checkbox>
                </template>
                <template v-slot:[`item.actif`]="{ item }">
                  <div class="d-flex">
                    <v-icon class="mr-2" :color="getColor(item.actif)">
                      {{ actifText(item.actif) }}
                    </v-icon>
                  </div>
                </template>
              </v-data-table>

              <v-btn name="Suivant" height="44px" color="primary" dark class="AccessButton mt-3 mr-3" tile elevation="0"
                :loading="loading" @click="currentStep--" fab width="180">
                Retour
              </v-btn>

              <v-btn name="Suivant" height="44px" color="secondary" dark class="AccessButton mt-3 mr-3" tile
                v-if="selectedUsers.length > 0" elevation="0" fab :loading="loading" @click="currentStep++" width="180">
                Suivant
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <BookListAffectation v-if="devis" :devis.sync="devis" :classe="classe"
                @additionalsBooks="additionalsBooks" @input="inputSelectBook"></BookListAffectation>

              <v-btn name="Suivant" height="44px" color="primary" dark class="AccessButton mt-3 mr-3" tile elevation="0"
                :loading="loading" @click="currentStep--" fab width="180">
                Retour
              </v-btn>

              <v-btn name="Suivant" height="44px" color="secondary" dark class="AccessButton mt-3 mr-3" tile
                elevation="0" fab v-if="selectedBooks.length > 0" @click="goResumer" width="180">
                Suivant
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="4">
              <!-- Show list user selected and book with amount -->
              <v-row>
                <v-col cols="12" class="text-h5">
                  <h1 class="text-h4 primary--text text-center">
                    Résumé de l'affectation
                  </h1>
                </v-col>
                <v-col cols="12" class="text-h5">
                  <h1 class="text-h5 primary--text text-center">
                    Utilisateurs sélectionnés
                  </h1>
                </v-col>
                <v-col cols="12" class="text-h5">
                  <v-data-table :headers="headers" :items="selectedUsers" :loading="loading" item-key="id"
                    class="elevation-1">
                  </v-data-table>
                </v-col>
                <v-col cols="12" class="text-h5">
                  <h1 class="text-h5 primary--text text-center">
                    Ouvrages à affecter
                  </h1>
                </v-col>
                <v-col cols="12" class="text-h5">
                  <v-data-table :headers="headersOuvrage" :items="selectedBooks" :loading="loading" item-key="id"
                    class="elevation-1">
                  </v-data-table>
                </v-col>
              </v-row>

              <v-btn name="Suivant" height="44px" color="primary" dark class="AccessButton mt-3 mr-3" tile elevation="0"
                :loading="loading" @click="currentStep--" fab width="180">
                Retour
              </v-btn>
              <v-btn name="Suivant" height="44px" color="secondary" dark class="AccessButton mt-3 mr-3" tile
                elevation="0" :loading="loading" @click="dialogConfirm = true" fab width="180">
                Confirmer
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
      <ValidationModal text="Êtes-vous sûr de vouloir effectuer cette action ? (Cette action est irréversible)"
        :status="dialogConfirm" @validated="affecter" @Canceled="cancel" />
    </v-col>
  </v-row>
  <v-row align="center" style="height: 80vh" justify="center" v-else>
    <v-progress-circular size="100" color="#fb4d61" indeterminate></v-progress-circular>
  </v-row>
</template>

<script>
import AdminService from "@/services/AdminService";
import BookListAffectation from "@/components/AffectationComponent/BookListAffectation.vue";
import ValidationModal from "@/components/Utility/ValidationModal.vue";
import AnneeService from "@/services/AnneeService.js";

export default {
  name: "AffectationTab",
  components: { BookListAffectation, ValidationModal },
  data: () => ({
    currentStep: 1,
    etablissements: [],
    classes: [],
    pageInfo: {
      page: 1,
      pageCount: 0,
      totalItems: 0,
      itemsPerPageOptions: [5, 10, 25, 50, 100],
      itemsPerPage: 10,
      itemsPerPageLabel: "Lignes par page",
      sortDesc: [],
      sortBy: [],
    },

    status: null,
    statuts: ["Actif", "Inactif"],

    loading: false,
    fetchingYear: true,

    etablissement: null,
    classe: null,

    headers: [
      { text: "Identifiant", value: "identifiant" },
      { text: "Nom", value: "nom" },
      { text: "Prénom", value: "prenom" },
      { text: "Année scolaire", value: "anneeScolaire" },
      { text: "Statut", "value": "actif" },
      { text: "Classes", value: "classes" },
    ],

    headersOuvrage: [
      { text: "ID", value: "id" },
      { text: "Nom", value: "name" },
      { text: "Matière", value: "matiere" },
      { text: "Référence", value: "refOffreDivalto" },
    ],
    anneeScolaire: undefined,
    anneesScolaires: undefined,

    utilisateurs: [],
    selectedUsers: [],
    selectedBooks: [],
    devis: null,
    dialogConfirm: false,
  }),
  mounted() {
    this.initSchoolYear();
    this.etablissementArray();
  },
  methods: {
    initSchoolYear() {
      AnneeService.fetchCurrentAnneeInfo().then(anneeInfo => {
        const anneeScolaire = anneeInfo.data.scolaire;
        this.anneeScolaire = anneeScolaire.code
        this.anneesScolaires = [anneeScolaire.code - 101, this.anneeScolaire]
      }).catch(() => {
        this.anneeScolaire = parseInt(process.env.VUE_APP_DEFAULT_SCHOOL_YEAR)
        this.anneesScolaires = [this.anneeScolaire - 101, this.anneeScolaire]
      }).finally(() => {
        this.fetchingYear = false
      })
    },
    validate() {
      if (this.currentStep === 1) {
        this.$refs["recherche-utilisateur"].validate();
        if (this.$refs["recherche-utilisateur"].validate()) {
          this.currentStep++;
          this.getUtilisateurEtablissement();
        }
      }
    },

    additionalsBooks(classe) {
      let aditionnalClasse = {
        id: 0,
        name: "LIVRES ADDITIONNELS",
        type: "devis",
        lots: [
          {
            id: 0,
            name: "LOTS ADDITIONNELS",
            type: "lot",
            livres: classe,
          },
        ],
      };

      // remove class LIVRES ADDITIONNELS
      this.devis.classes = this.devis.classes.filter(
        (classe) => classe.name !== "LIVRES ADDITIONNELS"
      );
      this.devis.classes.push(aditionnalClasse);
    },
    actifText(item) {
      if (item === true) {
        return "mdi-check-circle-outline";
      } else {
        return "mdi-close-circle-outline";
      }
    },
    getColor(Status) {
      if (Status === true) return "green";
      else return "secondary";
    },

    etablissementArray() {
      if (!this.etablissements.length) {
        AdminService.getEtablissementIds().then(({ data }) => {
          data.forEach((etablissement) => {
            this.etablissements.push(`${etablissement.id}`);
          });
        });
      }
    },
    classesArray(id) {
      this.loading = true;
      this.classesArrayLoading = false;
      this.classes = [];
      AdminService.getClasseByIDbyYear(id, this.anneeScolaire).then(
        ({ data }) => {
          data.classes.forEach((classe) => {
            this.classes.push(classe.name);
          });
          this.devis = data;
          this.classesArrayLoading = true;
          this.loading = false;
        }
      );
    },

    getUtilisateurEtablissement() {
      this.refreshPage();
    },

    setPage(page) {
      this.pageInfo.page = page;
      this.refreshPage();
    },

    changeSort(sort) {
      this.pageInfo.sortBy = [sort];
      this.refreshPage()
    },

    setPageSize(size) {
      this.pageInfo.itemsPerPage = size
      this.changeSort([])
    },

    changeSortDesc(sort) {
      this.pageInfo.sortDesc = [sort];
      this.setPage(1)
    },

    selectUser(user) {
      const userIndex = this.selectedUsers.map(u => u.identifiant).indexOf(user.identifiant);
      if (userIndex >= 0) {
        this.selectedUsers.splice(userIndex, 1)
      } else {
        this.selectedUsers.push(user);
      }
    },

    selectAll() {
      if (this.selectedUsers.length === this.pageInfo.totalItems) {
        this.selectedUsers = []
      } else {
        this.loading = true;
        this.fetchUsers({ sortBy: [], sortDesc: [], page: 1, itemsPerPage: this.pageInfo.totalItems, totalItems: this.pageInfo.totalItems })
          .then(({ data }) => {
            this.selectedUsers = data.content;
          }).finally(() => {
            this.loading = false;
          })
      }
    },

    fetchUsers({ sortBy, sortDesc, page, itemsPerPage, totalItems }) {
      return AdminService.searchUsers(
        page,
        itemsPerPage >= 0 ? itemsPerPage : totalItems,
        [this.classe],
        [this.etablissement],
        this.status === null ? null : this.status !== "Inactif",
        // In affectation we only use students
        ["ELEVE"],
        this.anneeScolaire,
        sortBy,
        sortDesc
      )
    },


    refreshPage() {
      this.loading = true;
      this.fetchUsers(this.pageInfo)
        .then(({ data }) => {
          this.utilisateurs = [];
          this.pageInfo.totalItems = data.totalElements;
          this.utilisateurs = data.content;
        })
        .catch((error) => {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goResumer() {
      this.currentStep++;
    },

    inputSelectBook(playload) {
      this.selectedBooks = playload;
    },
    cancel() {
      this.dialogConfirm = false;
    },

    affecter() {
      this.dialogConfirm = false;
      this.loading = true;

      let identifiants = this.selectedUsers.map(
        (utilisateur) => utilisateur.identifiant
      );
      let livres = this.selectedBooks.map((livre) => livre.refOffreDivalto);

      let data = {
        etablissement: this.etablissement,
        identifiants: identifiants,
        livres: livres,
      };

      AdminService.addBooks(data)
        .then(() => {
          this.loading = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Les livres ont bien été affectés",
          });
          this.$router.push({ name: "DashboardAdmin" });
        })
        .catch(() => {
          this.loading = false;
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Une erreur est survenue",
          });
        });
    },
  },
  watch: {
    etablissement: function (val) {
      this.classe = null;
      this.classesArray(val);
    },
  },
};
</script>

<style scoped></style>
